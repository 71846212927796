import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useAtomValue } from 'jotai';
import { matchSorter } from 'match-sorter';
import { toast } from 'sonner';
import { z } from 'zod';
import { isAdminUser, isContractFullySigned, isDistributor } from '@/config';
import { env } from '@/config/envs';
import { isProjectCreatedByCurrentUser } from '@/containers/Project/projectUtil';
import { SessionUser } from '@/types/next-auth';
import { Feedback, GetFeedback } from '@/types/to-migrate';
import {
  GET_UNHANDLED_ERROR,
  checkApiErrorResponse,
  checkApiSuccessResponse,
  fireErrorToast,
} from '@/utils';
import { clientLogger } from '@/utils/logger';
import { MasterProject as MasterProjectPublic } from '@prisma/client';
import { PreferencesResponseData } from '@smatio/commons';
import {
  EditHistoryRes,
  InvestmentGroup,
  InvestmentType,
  LABEL_OPTIONS,
  MARKETPLACE_OPTIONS_MAPPING,
  ProjectCustomNotificationRes,
  ProjectCustomNotificationsRes,
  ProjectListingMarketType,
  ProjectListingType,
  ProjectStatus,
  UserRoles,
  UserStatus,
} from '@smatio/commons';
import {
  CampaignFilterType,
  CampaignStatus,
  UseSecondaryMarket,
  UseSecondaryMarketOrder,
  OrderConfirmationDetailResponse,
  FilterOrder,
  UseProjectStatistics,
  GetContractDetails,
  UseClientDetails,
  UseClientInvestedProjects,
  UseClients,
  ClientTotalFunds,
  UseProjectOwnedByClient,
  UseNDADocumentList,
  UseProjectNDATemplate,
  UseUserNDATemplates,
  useNDASignProjectDetail,
  CampaignFilterRes,
  CampaignSearchPayload,
  UseCampaignDetails,
  EmailNotifications,
  NotificationsRes,
  UpdatePersonPayload,
  ProjectFilterObject,
  UseDistributedProjects,
  UseInitialDocuments,
  UseMyPrimaryProjects,
  UsePrivateProjectUsersList,
  UseProjectDetails,
  UseProjectMovements,
  UseProjectMultimedia,
  UseProjectQuestions,
  UseProjects,
  UsePublicOverviewProjects,
  UsePublicProjectDetails,
  UseSecondaryMarketListedProjects,
  UseAPIListingConfig,
  UseProjectsCount,
  CreateProjectResponse,
  UseProjectPreference,
  MasterProject,
  UseClientTransactions,
  UseDistributionTransaction,
  UseExternalTransactions,
  UseLatestDistributionInvestments,
  UseLatestInvestments,
  UseLatestTransactions,
  UseOrderSubscriptionDetails,
  UseTransactionDetails,
  UseWMTransactions,
  CompanyInviteList,
  CompanyTeamMembers,
  UserDetailRes,
  MyCompanyInvites,
  UseCompanyDetails,
  UseWealthManagerTotalFunds,
  WhoamiRes,
  ZefixResponse,
  UpdateCompanyDetails,
  UpdateUserDetails,
  VerifyCompanyResponse,
  GetProjectReportResponse,
  ProjectReport,
  UseSmatCornerDetails,
  UseDistributorDataRoomDocList,
} from '@smatio/commons';
import { PostProjectNotification } from '../containers/Project/ProjectDistributionNotification';
import { userAtom } from './atoms.service';
import {
  callAPISSR,
  callNextApi,
  fetchService,
  fetcher,
  getInfiniteKey,
  useAPI,
  useAPIImmutable,
  useAPIInfinite,
} from './fetch.service';

/**
 * @deprecated
 */
export const reloadSession = async () => {
  return axios.get('/api/auth/session?update');
};

export const redirectTo = (url: string, permanent = false) => {
  return {
    redirect: {
      destination: url,
      permanent,
    },
  };
};

export interface APIErrorType extends AxiosError {
  success: false;
}

const notifyError = (err: AxiosError<{ message?: string; error?: string }>): APIErrorType => {
  clientLogger({
    message:
      err.message ||
      err?.response?.data?.message ||
      err?.response?.data?.error ||
      GET_UNHANDLED_ERROR,
    pathname: err?.response?.config?.url,
  });
  return { ...err, success: false };
};

export const callWhoami = async (
  req?: Partial<AxiosRequestConfig>
): Promise<{ data: WhoamiRes; success: true } | APIErrorType> => {
  try {
    const { data } = await callAPISSR<WhoamiRes>({
      url: 'user/whoami',
      ...req,
    });
    return { data, success: true };
  } catch (err) {
    return notifyError(err);
  }
};

export const getPublicProjectDetails = (projectId: string) => {
  return callAPISSR<UsePublicProjectDetails>({
    url: `public/projectDetail/${projectId}`,
    method: 'GET',
  });
};

export const getProjectDetails = (projectId: string) => {
  return callAPISSR<UsePublicProjectDetails>({
    url: `projectDetails/${projectId}`,
    method: 'GET',
  });
};

export const getAnalyticsReportById = (
  reportId: string,
  req
): Promise<null | GetProjectReportResponse> => {
  if (!reportId) {
    return Promise.resolve(null);
  }
  return callAPISSR<GetProjectReportResponse>({
    url: `user/report/${reportId}`,
    method: 'GET',
    req,
  }).then((res) => res?.data);
};

export const callRedoRegistration = async ({ userId, update }): Promise<boolean> => {
  try {
    const { data } = await fetchService({
      url: `users/redoRegistration/${userId}`,
      method: 'PUT' as const,
    });

    if (checkApiSuccessResponse(data?.status)) {
      await update();
      return true;
    }
    if (checkApiErrorResponse(data?.status)) {
      throw new Error(data?.error);
    }
  } catch (e) {
    toast.error(e?.message || e);
    return false;
  }
};

export const callUpdatePersonalDetails = async (
  formData: UpdateUserDetails,
  userId?: string,
  accessToken?: string
): Promise<boolean> => {
  try {
    const { data, status } = await fetchService(
      {
        url: `mydetails/updatePersonalInfo`,
        method: 'PUT' as const,
        data: formData,
      },
      accessToken
    );

    if (checkApiSuccessResponse(data?.status || status)) {
      if (formData.phoneNumber && userId) {
        await callUpdateZitadelPhone({ userId, phoneNumber: formData.phoneNumber }, accessToken);
      }

      return true;
    }
    if (checkApiErrorResponse(data?.status)) {
      throw new Error(data?.error);
    }
  } catch (e) {
    fireErrorToast(e);
    return false;
  }
};

export const callUpdateCompanyDetails = async (
  formData: UpdateCompanyDetails & { username: string },
  accessToken?: string
): Promise<boolean> => {
  try {
    //TODO! should check and avoid editing details with CHE of an existing company different to current one
    const { data, status } = await fetchService(
      {
        url: `user/company`,
        method: 'PUT',
        data: formData,
      },
      accessToken
    );

    if (checkApiSuccessResponse(data?.status || status)) {
      return true;
    }
    if (checkApiErrorResponse(data?.status)) {
      throw new Error(data?.error);
    }
  } catch (e) {
    fireErrorToast(e);
    return false;
  }
};

export const callVerifyCompanyId = async (
  payload: { legalName: string; companyRegisterId: string; email: string },
  accessToken?: string
): Promise<VerifyCompanyResponse | null> => {
  try {
    const { data, status, error } = await fetchService<VerifyCompanyResponse>(
      {
        url: `users/checkCompanyDetail`,
        method: 'PUT' as const,
        data: {
          legalName: payload.legalName,
          companyRegisterId: payload.companyRegisterId,
          companyEmail: payload.email,
        },
      },
      accessToken
    );

    if (checkApiSuccessResponse(data?.status || status)) {
      return data;
    }
    if (checkApiErrorResponse(data?.status || status)) {
      throw new Error(error);
    }
  } catch (e) {
    fireErrorToast(e);
    return null;
  }
};

export const callCheckCompanyInZefix = async (
  params: { id?: string; name?: string },
  accessToken?: string
) => {
  return callNextApi<{ success: true; data: ZefixResponse }>(
    {
      url: '/companies',
      params,
    },
    accessToken
  );
};

export const callUpdateZitadelPhone = async (
  data: { userId: string; phoneNumber: string },
  accessToken?: string
) => {
  return callNextApi<{ data: ZefixResponse; success: true }>(
    {
      method: 'PATCH',
      url: '/auth/zitadel/user',
      data,
    },
    accessToken
  );
};

export const callUpdateCrmPerson = (
  data: UpdatePersonPayload & { companyName?: string; label?: LABEL_OPTIONS },
  accessToken?: string
) => {
  return callNextApi<{ status?: number }>(
    {
      method: 'PATCH',
      url: `/pipedrive/persons`,
      data,
    },
    accessToken
  );
};

export const callPreferencesCrmPerson = (email: string) => {
  return callNextApi<PreferencesResponseData>({
    method: 'GET',
    url: `/pipedrive/persons?email=${encodeURIComponent(email)}`,
  });
};

export const callCreateProject = async (
  formData: {
    isinNumber: string;
    investmentGroup: InvestmentGroup;
    projectListingMarketType: ProjectListingMarketType;
    projectListingType: ProjectListingType;
    investmentType: InvestmentType;
    isPrivateProject: boolean;
    isCampaignCreateRequest?: boolean;
    individualDeal?: boolean;
    excludedCountry?: string[];
  },
  accessToken?: string
): Promise<{ data: CreateProjectResponse; isError: boolean; error?: string }> => {
  try {
    const { data } = await fetchService<CreateProjectResponse>({
      url: `project/upload`,
      method: 'POST' as const,
      data: formData,
      ...(accessToken && {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    });

    if (checkApiErrorResponse(data?.status) || (data && 'error' in data)) {
      // @ts-expect-error check
      return { data, isError: true, error: data.error };
    }
    if (checkApiSuccessResponse(data?.status)) {
      return { data, isError: false };
    }
  } catch (e) {
    toast.error(e?.message || e);
    return { data: null, isError: true, error: e?.message };
  }
};

export const callAcceptTeamInvite = async ({ accessToken, invitationId }) =>
  callAPISSR<{ status: number; message: string }>({
    url: '/team/invite/accept',
    method: 'PUT' as const,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      invitationId,
    },
  });

export const callAcceptTeamInviteClientside = async (
  invitationId: string,
  accessToken?: string
) => {
  try {
    const { data, status } = await fetchService<{
      status: number;
      message: string;
      error?: string;
    }>(
      {
        url: 'team/invite/accept',
        method: 'PUT' as const,
        data: {
          invitationId,
        },
      },
      accessToken
    );
    if (checkApiSuccessResponse(data?.status || status)) {
      return data;
    }
    if (checkApiErrorResponse(data?.status || status)) {
      throw new Error(data?.error || data?.message);
    }
  } catch (e) {
    fireErrorToast(e);
    return null;
  }
};

// HOOKS

export const useLoggedUser = (shouldFetch = true) => {
  return useAPI<UserDetailRes>(`/user/me`, shouldFetch);
};

export const useCompanyDetails = (shouldFetch = true) => {
  return useAPI<UseCompanyDetails>(`/mydetails/companyInfo`, shouldFetch);
};
export const useCompanyTeamMembers = (shouldFetch = true) => {
  return useAPI<CompanyTeamMembers>(`/team/invite`, shouldFetch);
};

export const useProjectTeamEditionHistory = (projectId: string, shouldFetch = true) => {
  return useAPI<EditHistoryRes>(`/project/edit/history/${projectId}`, shouldFetch && !!projectId);
};
export const useCompanyInviteList = (shouldFetch = true) => {
  return useAPI<CompanyInviteList>(`/team/invite/invitations`, shouldFetch);
};

export const useMyCompanyPendingInvites = (shouldFetch = true) => {
  return useAPI<MyCompanyInvites>(`/team/invite/myInvitation`, shouldFetch);
};

export const useWhoami = (shouldFetch = true, swrConfig = undefined) => {
  return useAPI<WhoamiRes>(`/user/whoami`, shouldFetch, undefined, swrConfig);
};

export const getNonPublicProjectDetails = (masterProjectId: string, accessToken: string) => {
  return fetcher<UseProjectDetails>(
    `/projectDetails/${masterProjectId}`,
    {
      method: 'GET',
    },
    accessToken
  );
};

export const getProjectGrantedUsersList = (masterProjectId: string, accessToken: string) => {
  return fetcher<UsePrivateProjectUsersList>(
    `/projectDetails/privateProject/grantedUserList/${masterProjectId}`,
    { method: 'GET' },
    accessToken
  );
};

export const useProjectDetails = (
  masterProjectId: string,
  shouldFetch = true,
  accessToken?: string
) => {
  return useAPI<UseProjectDetails>(
    `projectDetails/${masterProjectId}`,
    shouldFetch && !!masterProjectId,
    accessToken && {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const useNdaDocumentList = (shouldFetch = true) => {
  return useAPI<UseNDADocumentList>(`/datarooms/ndaSignProjectList`, shouldFetch);
};

export const useProjectDocumentList = (shouldFetch = true) => {
  return useAPI<UseDistributorDataRoomDocList>(`datarooms/projectDocumentList`, shouldFetch);
};

export const useNotifications = (shouldFetch = true, swrOptions = undefined) => {
  return useAPI<z.infer<typeof NotificationsRes>>(
    `/notifications?type=desktop`,
    shouldFetch,
    undefined,
    swrOptions
  );
};

export const useProjectQuestions = (projectId: string, shouldFetch = true) => {
  return useAPI<UseProjectQuestions>(`/projectDetails/questions/${projectId}`, shouldFetch);
};
export const useProjectMultimedia = (masterProjectId: string, shouldFetch = true) => {
  return useAPI<UseProjectMultimedia>(`/smatcorners/masterProject/${masterProjectId}`, shouldFetch);
};

export const useSmatCornerList = (shouldFetch = true) => {
  return useAPI<unknown>(`/smatcorners`, shouldFetch);
};

export const useClients = (shouldFetch = true) => {
  // Distributor user should not trigger this fetch, since only WM manages clients
  const user = useAtomValue(userAtom);
  return useAPI<UseClients>(`clients`, shouldFetch && user && !isDistributor(user?.authorities));
};

export const useCampaignDetail = (campaignId, shouldFetch = true) => {
  return useAPI<UseCampaignDetails>(`marketCampaign/${campaignId}`, shouldFetch && !!campaignId);
};

export const useMyPrimaryProjects = (shouldFetch = true) => {
  return useAPI<UseMyPrimaryProjects>(
    `projectDetails/getMyListedPrimaryMarketProject`,
    shouldFetch
  );
};

export const usePublicProjectDetails = (projectId: string, shouldFetch = true) => {
  return useAPI<UsePublicProjectDetails>(`public/projectDetail/${projectId}`, shouldFetch);
};

export const useSmatCornerDetail = (postId: string, shouldFetch = true) => {
  return useAPI<UseSmatCornerDetails>(`smatcorners/${postId}`, shouldFetch);
};

export const useWMTransactions = (shouldFetch = true) => {
  return useAPI<UseWMTransactions>(`investment/transactions`, shouldFetch);
};

export const useDistributionTransactions = (shouldFetch = true) => {
  return useAPI<UseDistributionTransaction>(`investment/distributionTransactionList`, shouldFetch);
};

export const useExternalTransactions = (shouldFetch = true) => {
  return useAPI<UseExternalTransactions>(
    `investment/getExternalSubscriptionTransaction`,
    shouldFetch
  );
};

export const useProjectDistributionTransactions = (
  query: { masterProjectId: string; maxLimit?: string } = {
    masterProjectId: '',
    maxLimit: undefined,
  },
  shouldFetch = true
) => {
  const { masterProjectId, ...params } = query;
  const qParams = new URLSearchParams({ id: masterProjectId, ...params });
  return useAPI<UseDistributionTransaction>(
    `/investment/getTransactionByDistributedProject?${qParams}`,
    shouldFetch && !!query?.masterProjectId
  );
};

export const useProjectExternalTransactions = (
  query = { masterProjectId: '' },
  shouldFetch = true
) => {
  const { masterProjectId, ...params } = query;
  const qParams = new URLSearchParams({ distributedProjectId: masterProjectId, ...params });
  return useAPI<UseExternalTransactions>(
    `investment/getExternalSubscriptionTransaction?${qParams}`,
    shouldFetch && !!query?.masterProjectId
  );
};

export const useDistributedProject = (id, shouldFetch = true) => {
  return useAPI<UseDistributedProjects>(`distributedProjects/${id}`, shouldFetch && !!id);
};

export const useClientTransactions = (clientId, shouldFetch = true) => {
  // Distributor user should not trigger this fetch, since only WM manages clients
  const user = useAtomValue(userAtom);
  return useAPI<UseClientTransactions>(
    `portfolios/myTransactionList/${clientId}`,
    shouldFetch && !!clientId && !isDistributor(user?.authorities)
  );
};

export const useTransactionDetails = (transactionId, shouldFetch = true) => {
  return useAPI<UseTransactionDetails>(
    `investment/getTransactionDetails/${transactionId}`,
    shouldFetch && !!transactionId
  );
};

export const useClientDetails = (clientId, shouldFetch = true) => {
  // Distributor user should not trigger this fetch, since only WM manages clients
  const user = useAtomValue(userAtom);
  return useAPI<UseClientDetails>(
    `clients/${clientId}`,
    shouldFetch && !!clientId && user && !isDistributor(user?.authorities)
  );
};

export const useProjectInvestedByClient = (clientId, shouldFetch = true) => {
  return useAPI<UseClientInvestedProjects>(
    `portfolios/myInvestedProject/${clientId}`,
    shouldFetch && !!clientId
  );
};

export const useClientTotalFund = (clientId, shouldFetch = true) => {
  return useAPI<ClientTotalFunds>(`portfolios/mytotalFund/${clientId}`, shouldFetch && !!clientId);
};

export const usePrivateProjectUsersList = (masterProjectId, shouldFetch = true) => {
  return useAPI<UsePrivateProjectUsersList>(
    `/projectDetails/privateProject/grantedUserList/${masterProjectId}`,
    shouldFetch || !!masterProjectId
  );
};

export const useSecondaryMarketOrder = (orderId, shouldFetch = true) => {
  return useAPI<UseSecondaryMarketOrder>(`orders/${orderId}`, shouldFetch && !!orderId);
};

export const useSecondaryMarket = (filterOrder: Partial<FilterOrder>, shouldFetch = true) => {
  return useAPI<UseSecondaryMarket>(`orders/searches`, shouldFetch, {
    method: 'POST',
    data: filterOrder,
  });
};

export const useOrderConfirmationDetails = (id: string, shouldFetch = true) => {
  return useAPI<OrderConfirmationDetailResponse>(
    `/orders/confirmationDetail/${id}`,
    !!shouldFetch && !!id
  );
};

export const usePublicOverviewProjects = (shouldFetch = true) => {
  return useAPI<UsePublicOverviewProjects>(`/admin/project/landingPageProjectList`, shouldFetch);
};

export const useEditedOverviewProjects = (shouldFetch = true) => {
  return useAPI<UseEditedOverviewProjects>(
    `${env.NEXT_PUBLIC_DOMAIN}/api/db/landing_page_projects`,
    shouldFetch
  );
};

export interface UseEditedOverviewProjects {
  data: MasterProjectPublic[];
  count: number;
  success: boolean;
}

export const useSecondaryMarketListedProjects = (shouldFetch = true) => {
  return useAPI<UseSecondaryMarketListedProjects>(
    '/projectDetails/getAllProjectListedOnSecondaryMarket',
    shouldFetch
  );
};

export const useProjectMovement = (id: string, shouldFetch = true) => {
  return useAPI<UseProjectMovements>(`/investments/getProjectMovement/${id}`, shouldFetch && !!id);
};

export const useWealthManagerTotalFunds = (shouldFetch = true) => {
  return useAPI<UseWealthManagerTotalFunds>(`investment/totalFundUnderWealthManager`, shouldFetch);
};

export const useCampaigns = (
  shouldFetch = true,
  data: Omit<CampaignSearchPayload, 'isMyListedCampaign'> = {
    campaignFilterType: CampaignFilterType.IN_PROGRESS,
    status: CampaignStatus.APPROVED,
  }
) => {
  return useAPI<CampaignFilterRes, Omit<CampaignSearchPayload, 'isMyListedCampaign'>>(
    `/marketCampaign/filter`,
    shouldFetch,
    {
      method: 'POST',
      data,
    }
  );
};

export const useCampaignsInfinite = (limit, body = {}, shouldFetch = true, searchBy = '') => {
  return useAPIInfinite<CampaignFilterRes>(
    getInfiniteKey(
      '/marketCampaign/filter',
      limit,
      {
        method: 'POST',
        data: {
          campaignFilterType: CampaignFilterType.IN_PROGRESS,
          status: CampaignStatus.APPROVED,
          ...body,
        },
      },
      searchBy
    ),
    shouldFetch
  );
};

export const useProjectsInfinite = (
  limit,
  body: Partial<ProjectFilterObject> = {},
  searchBy = '',
  shouldFetch = true
) => {
  return useAPIInfinite<UseProjects>(
    getInfiniteKey(
      '/projectDetails/search',
      limit,
      {
        method: 'PUT',
        data: body,
      },
      searchBy
    ),
    shouldFetch
  );
};

export const useEmailNotification = (shouldFetch = true) => {
  return useAPI<EmailNotifications>(`mydetails/getEmailNotificationSetting`, shouldFetch);
};

export const useCurrencies = (shouldFetch = true) => {
  return useAPI<{
    currencyList: Array<{
      uuid?: string;
      isoCode: string;
      name: string;
    }>;
  }>(`/currencies`, shouldFetch);
};

export const useLatestInvestments = (
  { maxResult }: { maxResult?: number } = {},
  shouldFetch = true
) => {
  return useAPI<UseLatestInvestments>(
    `/investment/latestInvestment?maxResult=${maxResult}`,
    shouldFetch
  );
};

export const useLatestTransactions = (
  { maxResult, transactionType }: { maxResult?: number; transactionType?: string },
  shouldFetch = true
) => {
  return useAPI<UseLatestTransactions>(
    `investment/transactions?maxResult=${maxResult}&transactionType=${
      transactionType ? transactionType : ''
    }`,
    shouldFetch
  );
};

export const useNDADocumentDetail = (id, shouldFetch = true) => {
  return useAPI<useNDASignProjectDetail>(`/datarooms/ndaSignProjectDetail/${id}`, shouldFetch);
};

export const useDistributorDataRoomDocList = (id, shouldFetch = true) => {
  return useAPI<UseDistributorDataRoomDocList>(`/datarooms/projectDocumentList`, shouldFetch);
};
export const useInitialDocuments = (id, shouldFetch = true) => {
  return useAPI<UseInitialDocuments>(`/project/getDocument/${id}`, shouldFetch);
};

export const useAPIListingConfig = (shouldFetch = true, accessToken?: string) => {
  return useAPI<UseAPIListingConfig>(
    `/projectListingConfig`,
    shouldFetch,
    accessToken && {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const useOrderSubscriptionDetails = (id: string, shouldFetch = true) => {
  return useAPI<UseOrderSubscriptionDetails>(
    `/investments/subscriptionDetail/${id}`,
    shouldFetch && !!id
  );
};

export const useLatestDistributionInvestments = (
  { maxResult }: { maxResult?: number } = {},
  shouldFetch = true
) => {
  return useAPI<UseLatestDistributionInvestments>(
    `/investment/distributionTransactionList?maxResult=${maxResult}`,
    shouldFetch
  );
};

export const useProjectOwnedByClient = (id: string, shouldFetch = true) => {
  return useAPI<UseProjectOwnedByClient>(
    `/investments/getProjectMovementDetail/${id}`,
    shouldFetch && !!id
  );
};

export const useProjectsCount = (shouldFetch = true) => {
  const { data, ...rest } = useAPI<UseProjectsCount>(`/project/count`, shouldFetch);
  return {
    data: Object.entries(data?.countList || {})?.reduce((agg, [key, value]) => {
      agg[MARKETPLACE_OPTIONS_MAPPING[key]] = value;
      return agg;
    }, {}),
    ...rest,
  };
};
export const useContractDetails = (shouldFetch = true) => {
  return useAPI<z.infer<typeof GetContractDetails>>(`/contract/contractDetail`, shouldFetch);
};

export const useUserNDATemplates = (shouldFetch = true) => {
  return useAPI<UseUserNDATemplates>(`/datarooms/nda`, shouldFetch);
};

export const useProjectNDATemplate = (masterProjectId, shouldFetch = true) => {
  return useAPI<UseProjectNDATemplate>(
    `/datarooms/nda/${masterProjectId}`,
    masterProjectId && shouldFetch
  );
};

export const useProjectDefaultNDATemplate = (shouldFetch = true) => {
  return useAPI<any>(`/admin/dynamicConfigs`, shouldFetch);
};

export const useProjectsFavorite = (shouldFetch = true) => {
  return useAPI<UseProjectPreference>(`/project/listFavoriteProjects`, shouldFetch);
};
export const useProjectsExcluded = (shouldFetch = true) => {
  return useAPI<UseProjectPreference>(`/project/listExcludedProjects`, shouldFetch);
};

export const useProjectStatistics = <T>(
  id: string,
  params: {
    param: 'views' | 'chart' | 'events' | 'users' | 'emails' | 'links';
    interval: `${number}`;
    exclude: string;
  },
  shouldFetch = true
) => {
  return useAPI<UseProjectStatistics<T>>(
    `/api/analytics/project/${id}?${new URLSearchParams(params)?.toString()}`,
    !!id && shouldFetch,
    {
      baseURL: '/',
    }
  );
};

export const createProjectInPipedrive = ({
  user,
  project,
  auth,
}: {
  user?: Partial<SessionUser>;
  project: Pick<
    MasterProject,
    'masterProjectId' | 'projectName' | 'projectDescription' | 'projectListingType'
  >;
  auth?: string;
}) => {
  try {
    return fetchService({
      method: 'POST',
      url: '/api/pipedrive/deals',
      baseURL: env.NEXT_PUBLIC_DOMAIN,
      data: {
        user: { username: user.username, sub: user.sub },
        project: {
          masterProjectId: project.masterProjectId,
          projectName: project.projectName,
          projectDescription: project.projectDescription,
          projectListingType: project.projectListingType,
        },
      },
      ...(auth && {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      }),
    });
  } catch (e) {
    notifyError(e);
    return Promise.resolve();
  }
};

export const updateProjectInPipedrive = ({
  project,
  auth,
}: {
  project: Pick<MasterProject, 'status' | 'projectName'> & { termSheet: string; id: string };
  auth?: string;
}) => {
  try {
    return fetchService({
      method: 'PATCH',
      url:
        '/api/pipedrive/deals' +
        '?' +
        new URLSearchParams({
          id: project.id,
          term: project.projectName,
          status: project.status,
          termSheet: project.termSheet,
        }).toString(),
      baseURL: env.NEXT_PUBLIC_DOMAIN,
      headers: {
        ...(auth && {
          Authorization: `Bearer ${auth}`,
        }),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  } catch (e) {
    notifyError(e);
    return Promise.resolve();
  }
};

export const sendViewReport = async (report: ProjectReport, user: SessionUser) => {
  if (isAdminUser(user.authorities) && !isProjectCreatedByCurrentUser(report.userId, user)) {
    return Promise.resolve();
  }
  return fetchService<{ message: string; status: number }>({
    url: `/user/report/views/${report.uuid}`,
    method: 'PUT',
  });
};

export const useStaticHtmlTemplate = (fileName: string, shouldFetch = true) => {
  return useAPIImmutable<string>(`/templates/${fileName}`, shouldFetch && !!fileName, {
    baseURL: '/',
  });
};

export const useProjectNotificationsSent = (projectId: string, shouldFetch = true) => {
  return useAPI<ProjectCustomNotificationsRes>(
    `/project/notifications/${projectId}`,
    shouldFetch && !!projectId
  );
};

export const callPostProjectNotification = async (
  formData: PostProjectNotification,
  accessToken?: string
): Promise<boolean> => {
  try {
    const { data, status } = await fetchService<{
      status: number;
      message?: string;
      error?: string;
    }>(
      {
        url: `/project/notifications`,
        method: 'POST' as const,
        data: formData,
      },
      accessToken
    );
    if (checkApiSuccessResponse(data?.status || status)) {
      return true;
    }
    if (checkApiErrorResponse(data?.status)) {
      throw new Error(data?.error);
    }
  } catch (e) {
    fireErrorToast(e);
    return false;
  }
};

export const useFeaturedProjects = (user, shouldFetch = true) => {
  const isPublicFetch =
    !user?.authorities?.length ||
    user?.authorities?.includes(UserRoles.ROLE_NO_ROLE) ||
    user.status === UserStatus.UNDER_REGISTRATION ||
    !isContractFullySigned(user);
  const { data, ...rest } = useAPI<UseProjects>(
    `${isPublicFetch ? '/public/projectDetail/search' : '/projectDetails/search'}`,
    shouldFetch && user,
    {
      method: 'PUT',
      data: {
        projectListingMarketType: [],
        projectListingType: [],
        investmentType: [],
        status: ProjectStatus.APPROVED.toUpperCase(),
        featured: true,
      },
    }
  );

  if (!data) {
    return { data: [], ...rest };
  }
  const featuredArr = data?.masterProject;
  return {
    data: !featuredArr?.length
      ? []
      : matchSorter(featuredArr, '', {
          keys: ['createdDate'],
          baseSort: (a, b) => -String(a.rankedValue).localeCompare(b.rankedValue),
        }),
    ...rest,
  };
};

export const usePrimaryMarketProjects = (user, shouldFetch = true) => {
  const isPublicFetch =
    !user?.authorities?.length ||
    user?.authorities?.includes(UserRoles.ROLE_NO_ROLE) ||
    user.status === UserStatus.UNDER_REGISTRATION ||
    !isContractFullySigned(user);
  const { data, ...rest } = useAPI<UseProjects>(
    `${isPublicFetch ? '/public/projectDetail/search' : '/projectDetails/search'}`,
    shouldFetch && user,
    {
      method: 'PUT',
      data: {
        projectListingMarketType: [ProjectListingMarketType.PRIMARY],
        projectListingType: [],
        investmentType: [],
        status: ProjectStatus.APPROVED.toUpperCase(),
      },
    }
  );

  if (!data) {
    return { data: [], ...rest };
  }
  const projects = data?.masterProject;
  return {
    data: !projects?.length
      ? []
      : matchSorter(projects, '', {
          keys: ['createdDate'],
          baseSort: (a, b) => -String(a.rankedValue).localeCompare(b.rankedValue),
        }),
    ...rest,
  };
};

export const useSecondaryMarketProjects = (user, shouldFetch = true) => {
  const isPublicFetch =
    !user?.authorities?.length ||
    user?.authorities?.includes(UserRoles.ROLE_NO_ROLE) ||
    user.status === UserStatus.UNDER_REGISTRATION ||
    !isContractFullySigned(user);
  const { data, ...rest } = useAPI<UseProjects>(
    `${isPublicFetch ? '/public/projectDetail/search' : '/projectDetails/search'}`,
    shouldFetch && user,
    {
      method: 'PUT',
      data: {
        projectListingMarketType: [ProjectListingMarketType.SECONDARY],
        projectListingType: [],
        investmentType: [],
        status: ProjectStatus.APPROVED.toUpperCase(),
      },
    }
  );

  if (!data) {
    return { data: [], ...rest };
  }
  const projects = data?.masterProject;
  return {
    data: !projects?.length
      ? []
      : matchSorter(projects, '', {
          keys: ['createdDate'],
          baseSort: (a, b) => -String(a.rankedValue).localeCompare(b.rankedValue),
        }),
    ...rest,
  };
};

export const useProjectCustomNotification = (notificationId: string, shouldFetch = true) => {
  return useAPI<ProjectCustomNotificationRes>(
    `/project/notifications/detail/${notificationId}`,
    shouldFetch && !!notificationId
  );
};

export const useGetFeedbacks = (id: string, shouldFetch = true) => {
  return useAPI<GetFeedback>(`/project/user/feedback/${id}`, shouldFetch && !!id);
};

export const callProjectFeedback = async (
  feedbackData: Feedback,
  abortController: AbortController
) => {
  try {
    const { data, status } = await fetchService<{
      status: number;
      message?: string;
      error?: string;
    }>({
      url: `/project/user/feedback`,
      method: 'POST' as const,
      data: feedbackData,
      signal: abortController.signal,
    });
    if (checkApiSuccessResponse(data?.status || status)) {
      return true;
    } else {
      throw new Error(data?.error || 'Something went wrong submitting your feedback');
    }
  } catch (e) {
    fireErrorToast(e);
    return false;
  }
};

export const useProjectSubscriptionOptions = (accessToken?: string, shouldFetch = true) => {
  return useAPI<{
    subscriptions: {
      uuid: string;
      name: string;
      description: string;
      curreny: string;
      basePrice: number;
      active: boolean;
    }[];
    status: number;
  }>(`/project/subscription/subscriptions`, shouldFetch, {
    ...(accessToken && {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  });
};

export const useSubscriptionOnProject = (masterProjectId: string, shouldFetch = true) => {
  return useAPI<{
    subscriptions: {
      uuid: string;
      subscription: string;
      subscriptionUuid: string;
      status: 'ACTIVE' | 'CANCELLED' | 'EXPIRED' | 'PAID' | 'AWAITING_PAYMENT';
      projectName: string;
      startDate: string;
      endDate: string;
      createdDate: string;
    }[];
    status: number;
  }>(`/project/subscription/${masterProjectId}`, shouldFetch && !!masterProjectId);
};

export const updateProjectSubscription = async (payload: {
  status: 'ACTIVE' | 'CANCELLED' | 'EXPIRED' | 'PAID' | 'AWAITING_PAYMENT';
  subscriptionId: string;
  endDate: string;
}) => {
  try {
    const { data, status } = await fetchService<{
      status: number;
      message?: string;
      error?: string;
    }>({
      url: `/project/subscription/update`,
      method: 'PUT' as const,
      data: payload,
    });
    if (checkApiErrorResponse(data?.status || status) || (data && 'error' in data)) {
      throw new Error(data?.error || 'Something went wrong updating the subscription');
    }
    return data;
  } catch (e) {
    fireErrorToast(e);
    return false;
  }
};

export const createProjectSubscription = async (
  payload: {
    masterProjectId: string;
    subscriptionId: string;
    addonsId: string[];
    startDate: string;
    endDate: string;
  },
  token?: string
) => {
  try {
    const { data, status } = await fetchService<{
      status: number;
      message?: string;
      error?: string;
    }>(
      {
        url: `/project/subscription/subscribe`,
        method: 'POST' as const,
        data: payload,
      },
      token
    );
    if (checkApiErrorResponse(data?.status || status) || (data && 'error' in data)) {
      throw new Error(data?.error || 'Something went wrong updating the subscription');
    }
    return data;
  } catch (e) {
    fireErrorToast(e);
    return { error: e.message, status: e?.status || e?.response?.status || 500 };
  }
};

export const updateProjectStatusFromDraftToPending = async (projectId: string, token?: string) => {
  try {
    const { data, status } = await fetchService<{
      status: number;
      message?: string;
      error?: string;
    }>(
      {
        url: `/project/status/${projectId}`,
        method: 'PUT' as const,
      },
      token
    );

    if (checkApiErrorResponse(data?.status || status) || (data && 'error' in data)) {
      throw new Error(data?.error || 'Something went wrong updating the project status');
    }
    return data;
  } catch (e) {
    fireErrorToast(e);
    return { error: e.message, status: e?.status || e?.response?.status || 500 };
  }
};
