import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { env } from '@/config/envs';
import { tokenAtom, userAtom } from '@/services/atoms.service';
import { useUTM } from '@/utils/hooks';
import {
  GA4_CONVERSIONS,
  GA4_CUSTOM_EVENTS,
  GA4_GENERIC_EVENTS,
  TrackAction,
  TrackMetadata,
} from '@smatio/commons';
import { identifyGoSquareUser } from '../GoSquared/GoSquared';

// Reference https://developers.google.com/tag-platform/gtagjs/reference

const GA_MEASUREMENT_ID = env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
const DEBUG = !!env.NEXT_PUBLIC_GA_DEBUG;
interface TrackEvent {
  category: GA4_CUSTOM_EVENTS | GA4_GENERIC_EVENTS;
  action: TrackAction;
  metadata?: TrackMetadata;
}

const trackGA4Event = async ({ category, action, metadata }: TrackEvent) => {
  if (!action || typeof gtag === 'undefined') return;
  gtag('event', action, {
    event_category: category,
    ...metadata,
    ...(DEBUG && {
      event_callback: function () {
        console.log(`[DEBUG EVENT]: ${category}/${action}`);
      },
    }),
  });
};

export const sendGA4Conversion = (
  conversionName: keyof typeof GA4_CONVERSIONS,
  metadata: TrackMetadata = {}
) => {
  if (typeof gtag === 'undefined') return;
  gtag('event', 'conversion', {
    send_to: `${GA_MEASUREMENT_ID}/${GA4_CONVERSIONS[conversionName]}`,
    conversion_type: GA4_CONVERSIONS[conversionName],
    ...metadata,
  });
};

/**
 * Use this function to conditionally track some events. Useful for usage in the project/campaign pages to avoid tracking events where user is the lister.
 * @param condition Used to track only if condition is met.
 * @param config { type: 'conversion' | ' event', metadata?: TrackMetadata, payload: TrackEvent | keyof typeof GA4_CONVERSIONS }
 * @returns
 */

export const TrackWithCondition = (
  condition: boolean,
  {
    type,
    payload,
    metadata,
  }: {
    type: 'conversion' | 'event';
    metadata?: TrackMetadata;
    payload: TrackEvent | keyof typeof GA4_CONVERSIONS;
  }
) => {
  if (typeof gtag === 'undefined') return;
  // Do not track if condition is not met.
  if (!condition) return;
  if (type === 'conversion' && isConvesionPayload(payload)) {
    sendGA4Conversion(payload, metadata);
  }
  if (type === 'event' && isEventPayload(payload)) {
    trackGA4Event(payload);
  }
};

const isEventPayload = (data: any): data is TrackEvent => {
  return data && typeof data === 'object' && 'category' in data && 'action' in data;
};

const isConvesionPayload = (data: any): data is keyof typeof GA4_CONVERSIONS => {
  return data && typeof data === 'string' && Object.keys(GA4_CONVERSIONS).includes(data);
};

const identifyAnalyticsUser = (userId?: string) => {
  if (typeof gtag === 'undefined') return;
  gtag('set', 'user_id', userId);
  gtag('set', 'client_id', userId);
};

const extractProjectId = (url: string): string | null => {
  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
  const match = url.match(uuidRegex);
  return match ? match[0] : null;
};

const GoogleAnalytics = () => {
  const router = useRouter();
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status, data } = useSession({ required: false });
  const setUser = useSetAtom(userAtom);
  const setToken = useSetAtom(tokenAtom);

  const userId = data?.user?.sub;

  useUTM();
  useEffect(() => {
    if (status === 'authenticated') {
      setUser(data?.user);
      setToken(data?.access_token);
      identifyAnalyticsUser(data?.user?.sub);
      identifyGoSquareUser({ email: data?.user?.username, name: data?.user?.fullName });
    }
  }, [status]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (typeof gtag !== 'undefined') {
        // <domain>/public/show-project-detail/6c9a5bd0-096d-4758-8b50-2597a8d4b067
        // <domain>/project/907f4050-6b7b-4819-a33f-cba382a9b1fe

        const projectId = extractProjectId(url);
        trackGA4Event({
          category: GA4_GENERIC_EVENTS.PageView,
          action: 'page_view',
          metadata: {
            page_path: url,
            ...(projectId && { project_id: projectId }),
            ...(projectId && { projectId }),
            ...(userId && { userId: userId }),
          },
        });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, router.asPath]);

  return null;
};

const resetGA = () => {
  if (typeof gtag === 'undefined') return;
  identifyAnalyticsUser(undefined);
};

const getGAConfig = (param: string) => {
  if (typeof gtag === 'undefined') return;
  return gtag('get', GA_MEASUREMENT_ID, param, (data) => {
    return data;
  });
};

export { getGAConfig, trackGA4Event, GoogleAnalytics, resetGA };
